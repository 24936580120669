import React from 'react';
import styled from 'styled-components';

const Intro = styled.h1`
  text-align: center;
  padding: 0 2rem 1rem;
  font-size: 18px;
  @media (min-width: 600px) {
    font-size: 20px;
  }
  @media (min-width: 800px) {
    font-size: 25px;
  }
`;

export default function SiteIntro({ data }) {
  return (
    <>
      <Intro>{data.home.siteIntro}</Intro>
    </>
  );
}
