import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image';

const FeaturedGalleriesStyled = styled.ul`
  list-style: none;
  margin: 0 0 3rem;
  padding: 0;

  li {
    margin-bottom: 2rem;
    background-color: var(--black);
  }

  a {
    text-decoration: none;
    display: block;
    transition: var(--tran);
    &:hover,
    &:active,
    &:focus {
      opacity: 0.5;
    }
  }
`;

const FeaturedGalleryBg = styled(BackgroundImage)`
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 280px;
  @media (min-width: 600px) {
    height: 360px;
  }
  @media (min-width: 768px) {
    height: 400px;
  }
  @media (min-width: 1024px) {
    height: 500px;
  }
`;

const FeaturedGalleryTitle = styled.h3`
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 1rem;
  border: 1px solid var(--white);
  min-width: 180px;
  text-align: center;
  text-transform: uppercase;
`;

export default function FeaturedGalleries({ data }) {
  return (
    <FeaturedGalleriesStyled>
      {data.home.featuredGalleries.map((image) => (
        <li
          key={image.featuredImage.asset.sha1hash}
          className="featured-gallery"
        >
          <Link to={`/gallery/${image.slug.current}`}>
            <FeaturedGalleryBg
              fluid={image.featuredImage.asset.fluid}
              alt={image.featuredImage.alt}
            >
              <FeaturedGalleryTitle>{image.title}</FeaturedGalleryTitle>
            </FeaturedGalleryBg>
          </Link>
        </li>
      ))}
    </FeaturedGalleriesStyled>
  );
}
