import React from 'react';
import { graphql } from 'gatsby';
// import styled from 'styled-components';
import SEO from '../components/seo';

// import Hero from '../components/Hero';
import SiteIntro from '../components/SiteIntro';
import CtaPrimary from '../components/CtaPrimary';
import FeaturedGalleries from '../components/FeaturedGalleries';
import CtaSecondary from '../components/CtaSecondary';

const IndexPage = ({ data }) => (
  <>
    <SEO title="Home" />

    {/* <Hero data={data} /> */}
    <FeaturedGalleries data={data} />
    <SiteIntro data={data} />
    <CtaPrimary data={data} />
    {/* <CtaSecondary data={data} /> */}
  </>
);

export const HomeQuery = graphql`
  {
    home: sanityFrontPage {
      title
      siteIntro
      ctaPrimary
      ctaSecondary
      heroGallery {
        asset {
          fluid(maxWidth: 300) {
            ...GatsbySanityImageFluid
          }
          id
        }
        alt
      }
      featuredGalleries {
        featuredImage {
          alt
          asset {
            fluid(maxWidth: 1200) {
              ...GatsbySanityImageFluid
            }
            sha1hash
          }
        }
        title
        slug {
          current
        }
      }
    }
    settings: sanitySiteSettings {
      email
    }
  }
`;

export default IndexPage;
