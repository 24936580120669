import React from 'react';
import styled from 'styled-components';

const CallToAction = styled.p`
  text-align: center;
  padding: 0 2rem 2rem;
  font-size: 18px;
  @media (min-width: 600px) {
    font-size: 20px;
  }
  @media (min-width: 800px) {
    font-size: 25px;
  }

  a {
    display: block;
    background-color: white;
    border: 1px solid black;
    padding: 0.2rem 1rem;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 1rem;
    @media (min-width: 600px) {
      font-size: 18px;
    }
    @media (min-width: 800px) {
      display: inline-block;
      font-size: 20px;
      margin-left: 1rem;
      margin-top: 0;
    }
    transition: all 0.3s ease-in-out;
    &:hover,
    &:active,
    &:focus {
      color: white;
      background-color: black;
    }
  }
`;

export default function CtaSecondary({ data }) {
  return (
    <>
      <CallToAction>
        {data.home.ctaSecondary}{' '}
        <a href={`mailto:${data.settings.email}`}>Hello</a>
      </CallToAction>
    </>
  );
}
